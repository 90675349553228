import React from 'react';

const PageNotFound = () => {
	return (
		<>
			<h1>404</h1>
		</>
	);
};

export default PageNotFound;
